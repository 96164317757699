import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { GET_USERS, GET_EXPORT_USERS } from '../../../services/data-layer';

const UserContext = createContext({});
const useUser = () => useContext(UserContext);

const initialState = {
  error: '',
  feedback: null,
  users: {
    rows: [],
    count: 0,
    hasMore: false,
  },
  selectedUsers: null,
  selectedUser: null,
  isEditUserStatusOpen: false,
  userExportSuccess: null
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const actions = {
  setUsers: 'SET_USER_LIST',
  setConfirmRecertification: 'SET_CONFIRM_RECERTIFIATION',
  setFeedback: 'SET_FEEDBACK',
  setError: 'SET_ERROR',
  setSelectedUser: 'SET_SELECTED_USER',
  setIsEditUserStatusOpen: 'SET_IS_EDIT_USER_STATUS_OPEN',
  setExportSuccess: 'SET_EXPORT_SUCCESS',
};

const userReducer = (state, { action, payload }) => {
  const {
    setUsers,
    setConfirmRecertification,
    setFeedback,
    setError,
    setSelectedUser,
    setIsEditUserStatusOpen,
    setExportSuccess,
  } = actions;
  switch (action) {
    case setUsers: {
      return {
        ...state,
        error: null,
        users: { ...payload } || initialState.users,
      };
    }
    case setConfirmRecertification: {
      return {
        ...state,
        error: null,
        selectedUsers: payload,
      };
    }
    case setFeedback: {
      return {
        ...state,
        error: null,
        feedback: payload,
      };
    }
    case setSelectedUser: {
      return {
        ...state,
        selectedUser: payload,
      };
    }
    case setIsEditUserStatusOpen: {
      return {
        ...state,
        isEditUserStatusOpen: payload,
      };
    }
    case setExportSuccess: {
      return {
        ...state,
        userExportSuccess: payload,
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid role action');
  }
};

const UserProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    userReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const confirmRecertification = (users) =>
    dispatch(actions.setConfirmRecertification, users);

  const setFeedbackMessage = (value) => {
    dispatch(actions.setFeedback, value);
  };

  // Get Users lazy.
  const [
    getUsers,
    { loading: fetchingUsers, refetch: refetchUsers },
  ] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      setFeedbackMessage({ type: 'error' });
      dispatch(actions.setError, err);
    },
    onCompleted: (data) => dispatch(actions.setUsers, data?.getUsers),
  });

  const [
    getExportUsers,
    { loading: fetchingExportUsers, refetch: refetchExportUsers },
  ] = useLazyQuery(GET_EXPORT_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setFeedbackMessage({ type: 'error' });
      dispatch(actions.setExportSuccess, false);
    },
    onCompleted: () => dispatch(actions.setExportSuccess, true),
  });

  return (
    <UserContext.Provider
      value={{
        ...state,
        ...props,
        fetchingUsers,
        getUsers,
        confirmRecertification,
        setFeedbackMessage,
        refetchUsers,
        getExportUsers,
        fetchingExportUsers,
        refetchExportUsers,
        setSelectedUser: (payload) =>
          dispatch(actions.setSelectedUser, payload),
        setIsEditUserStatusOpen: (payload) =>
          dispatch(actions.setIsEditUserStatusOpen, payload),
        setUserExportSuccess: (payload) => 
          dispatch(actions.setExportSuccess, payload),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserProvider as default, useUser };
