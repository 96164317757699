import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import React from 'react';
import { formatDate } from '../../../../../utilities/StringUtils';
import { canApproveOrDenyUser } from '../../../../users/authorization';
import { canEditUserStatus } from '../../../../users/components/modal/edit-user-status-modal';
import { Status } from '../../constants/user-details-constants';
import { useUserDetails } from '../providers';
import ApproveUserAccessButton from './approve-access-button';
import DenyUserAccessModal from './deny-access-modal';
import { EditUserStatusButton } from './edit-status-button';
import { EditUserProfileButton } from './edit-user-profile-button';
import './user-header.scss';
import UserName from './user-name';
import UserStatus from './user-status';
import { canEditUserProfile } from '../../authorization';

const emDashUnicode = '\u2014';

// TODO use user status utility
const isPendingRole = (user) => user?.status?.id === Status.PENDING_ROLE;

const actions = (currentUserId) => {
  const { user } = useUserDetails();
  const ability = useAppAbility();
  const showApproveDeny = canApproveOrDenyUser(ability) && isPendingRole(user);
  const showEditStatus =
    user &&
    currentUserId &&
    canEditUserStatus(ability, user.status.id, currentUserId, user);
  const showEditProfile = user && canEditUserProfile(ability, user.status.id);

  return (
    <div className="flex-align-self-center">
      {showApproveDeny && (
        <>
          <DenyUserAccessModal />
          <ApproveUserAccessButton />
        </>
      )}
      {showEditStatus && <EditUserStatusButton />}
      {showEditProfile && <EditUserProfileButton userId={user.id} />}
    </div>
  );
};

const UserHeader = () => {
  const { currentUser } = useCurrentUser();
  const { user } = useUserDetails();
  const fullName = user ? user.fullName : '';
  return (
    <>
      <div className="desktop:display-flex desktop:flex-justify">
        <div className="desktop:display-flex">
          <UserName>{fullName}</UserName>
          <UserStatus className="flex-align-self-center padding-bottom-2 desktop:padding-left-5 desktop:padding-bottom-0" />
        </div>
        {actions(currentUser?.id)}
      </div>
      <div className="desktop:display-flex bg-base-lightest padding-y-2 padding-x-3 margin-top-2">
        <span>
          <strong>Registration date: </strong>
          {formatDate(user?.createdAt)}
        </span>
        <div className="padding-y-1 margin-x-4 margin-y-neg-1 desktop:border-left desktop:border-base-lighter" />
        <span>
          <strong>Last logged in date: </strong>
          {formatDate(user?.lastLogin)}
        </span>
        <div className="padding-y-1 margin-x-4 margin-y-neg-1 desktop:border-left desktop:border-base-lighter" />
        <span>
          <strong>Last status updated date: </strong>
          {formatDate(user?.updatedStatusAt) || emDashUnicode}
        </span>
        <div className="padding-y-1 margin-x-4 margin-y-neg-1 desktop:border-left desktop:border-base-lighter" />
        <span>
          <strong>Last status updated by: </strong>
          {user?.updatedStatusByUser?.fullName || emDashUnicode}
        </span>
      </div>
    </>
  );
};

export default UserHeader;
