/* eslint-disable import/prefer-default-export */
export const canCertifyUser = (ability) => ability.can('certify', 'User');
export const canApproveOrDenyUser = (ability) =>
  ability.can('approve', 'User');
export const canActivateOrDeactivateUser = (ability) =>
  ability.can('activate', 'User');
export const canUpdateUser = (ability) =>
  ability.can('update', 'User');
export const canManageAll = (ability) => ability?.can('manage', 'all');
export const canSetAsDefaulted = (ability) => ability?.can('update', 'UserDefaultedStatus');
export const canEditBidderPassport = (ability) => ability?.can('update', 'BidderPassport');