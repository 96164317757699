import {
  AFPTableRowAction,
  Button,
  CounterTag,
  StatusBadge,
  FilterTableFrame,
  Alert,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { canCertifyUser, canManageAll } from '../authorization';
import { Types } from '../constants/user-constants';
import { useUser, useUserFilter } from '../providers';
import TableWrapper from './afp-table-wrapper';
import {
  canEditUserStatus,
  EditUserStatusModal,
} from './modal/edit-user-status-modal';
import ConfirmRecertificationModal from './modal/recertification-modal';
import UserFilters from './user-filters';
import UserStatusTag from './user-status-tag';

const UsersTable = () => {
  const history = useHistory();
  const {
    getUsers,
    users,
    fetchingUsers,
    confirmRecertification,
    setFeedbackMessage,
    selectedUser,
    isEditUserStatusOpen,
    setSelectedUser,
    setIsEditUserStatusOpen,
    getExportUsers,
    userExportSuccess,
    setUserExportSuccess,
    fetchingExportUsers,
  } = useUser();

  const itemsPerPageOptions = [10, 25, 50];
  const { filters } = useUserFilter();
  const [sort, setSort] = useState('lastName ASC');
  const [selectedUserRows, setSelectedUserRows] = useState([]);
  const [paginationState, setPaginationState] = useState({
    limit: itemsPerPageOptions[0],
    offset: 0,
    currentPage: 1,
    isReset: false,
  });
  const [noExportUsers, setNoExportUsers] = useState(false);
  const [startExport, setStartExport] = useState(false);
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();

  const tableRef = React.createRef();

  const cellRecertification = (value) => {
    let recertStatus;
    // eslint-disable-next-line default-case
    switch (value) {
      case 'Pending':
        recertStatus = {
          label: value,
          status: 'Warning-Gray',
        };
        break;
      case 'Past due':
        recertStatus = {
          label: value,
          status: 'Urgent-Gray',
        };
        break;
      case 'Recertified':
        recertStatus = {
          label: value,
          status: 'Ready-Gray',
        };
        break;
    }

    return recertStatus;
  };

  const exportUsers = () => {
    setNoExportUsers(false);
    setUserExportSuccess(false);
    setStartExport(true);
  };

  useEffect(() => {
    if (startExport) {
      setStartExport(false);
      const hasUsers = !filters?.conditions || users.count > 0;
      if (!hasUsers) {
        setNoExportUsers(true);
      } else {
        getExportUsers({
          variables: {
            limit: users.count,
            offset: 0,
            order: sort,
            filters,
          },
        });
      }
    }
  }, [startExport]);

  const getData = () => {
    const { limit, offset } = paginationState;
    getUsers({
      variables: {
        limit,
        offset,
        order: sort,
        filters,
      },
    });
  };
  useEffect(() => {
    getData();
  }, [sort, paginationState, filters]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  const columnsByUserType = (userTypeId) => {
    const actionsLabel = {
      view: 'View profile',
      editStatus: 'Edit user status',
    };
    if (userTypeId === Types.GSA_EMPLOYEE || canManageAll(ability)) {
      actionsLabel.recertify = 'Recertify';
    }

    const actionsColumn = {
      getActions(user) {
        const { view, recertify, editStatus } = actionsLabel;

        const actions = [
          {
            icon: 'visibility',
            label: view,
          },
        ];

        if (
          canEditUserStatus(ability, user.status.id, currentUser?.id, user?.id)
        ) {
          actions.push({
            icon: 'edit',
            label: editStatus,
          });
        }

        if (
          canCertifyUser(ability) &&
          user.id !== currentUser.id &&
          user.userType.id === Types.GSA_EMPLOYEE
        ) {
          actions.push({
            icon: 'verified_user',
            label: recertify,
          });
        }

        return actions;
      },
      execute(operation, user) {
        const { view, recertify, editStatus } = actionsLabel;
        if (operation === view) {
          history.push(`/admin/users/${user.id}`);
        }
        if (operation === recertify) {
          confirmRecertification([user.id]);
        }
        if (operation === editStatus) {
          setSelectedUser(user);
          setIsEditUserStatusOpen(true);
        }
      },
    };

    const actionsCell = (props) => {
      /* eslint-disable react/prop-types */
      const { cell } = props;
      const { row } = cell;
      const { original: user } = row;
      const rowActions = actionsColumn.getActions(user);
      return (
        <AFPTableRowAction
          actions={rowActions}
          actionTitle="Standard Item Action"
          onSelectAction={(operation) => {
            actionsColumn.execute(operation, user);
          }}
          {...props}
        />
      );
    };

    const commonColumns = [
      {
        Header: 'First name',
        accessor: 'firstName',
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
      },
      {
        Header: 'User status',
        accessor: 'status.name',
        Cell: (cell) => {
          const { value } = cell;
          return <UserStatusTag value={value} />;
        },
      },
    ];

    const recertificationCellStatus = (cell) => {
      const { value } = cell;
      if (value) {
        const { status, label } = cellRecertification(value);
        return <StatusBadge variant={status}>{label}</StatusBadge>;
      }
      return '-';
    };

    const columnsByType = {
      [Types.CUSTOMER]: [
        ...commonColumns,
        {
          Header: 'Actions',
          sortable: false,
          headerClassName: 'cell-center',
          cellClassName: 'cell-center',
          Cell: actionsCell,
        },
      ],
      [Types.VENDOR]: [
        ...commonColumns,
        {
          Header: 'Actions',
          sortable: false,
          headerClassName: 'cell-center',
          cellClassName: 'cell-center',
          Cell: actionsCell,
        },
      ],
      [Types.GSA_EMPLOYEE]: [
        ...commonColumns,
        {
          Header: 'User type',
          accessor: 'userType.name',
        },
        {
          Header: 'Recertification status',
          sortable: false,
          accessor: 'recertificationStatus',
          Cell: recertificationCellStatus,
        },
        {
          Header: 'Actions',
          sortable: false,
          headerClassName: 'cell-center',
          cellClassName: 'cell-center',
          Cell: actionsCell,
        },
      ],
      all: [
        {
          Header: 'First name',
          accessor: 'firstName',
        },
        {
          Header: 'Last name',
          accessor: 'lastName',
        },
        {
          Header: 'User status',
          accessor: 'status.name',
          Cell: (cell) => {
            const { value } = cell;
            return <UserStatusTag value={value} />;
          },
        },
        {
          Header: 'User type',
          accessor: 'userType.name',
        },
        {
          Header: 'Recertification status',
          sortable: false,
          accessor: 'recertificationStatus',
          Cell: recertificationCellStatus,
        },
        {
          Header: 'Actions',
          sortable: false,
          headerClassName: 'cell-center',
          cellClassName: 'cell-center',
          Cell: actionsCell,
        },
      ],
    };

    if (canManageAll(ability)) {
      return columnsByType.all;
    }

    return columnsByType[userTypeId] || [];
  };

  const columns = useMemo(() => {
    return columnsByUserType(currentUser?.userType?.id);
  }, [currentUser?.userType?.id]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const bulkActionsByUserType = (userTypeId) => () => {
    if (userTypeId === Types.CUSTOMER) {
      return null;
    }
    return (
      <>
        {canCertifyUser(ability) && (
          <Button
            label="Recertify user(s)"
            leftIcon={{
              name: 'verified_user',
            }}
            type="button"
            variant="primary"
            id="recertify-users"
            data-testid="recertify-users-btn-testid"
            className="usa-button float-right"
            onClick={() => {
              if (selectedUserRows.length > 0) {
                confirmRecertification(selectedUserRows);
              } else {
                setFeedbackMessage({
                  type: 'error',
                  message: (
                    <span>Please select at least one user to recertify.</span>
                  ),
                });
              }
            }}
          />
        )}
        <Button
          label="Export data"
          leftIcon={{
            name: 'file_download',
          }}
          type="button"
          variant="primary"
          id="export-users"
          data-testid="export-users-btn-testid"
          className="usa-button float-right"
          onClick={exportUsers}
        />
      </>
    );
  };

  const userTableWrapperProps = {
    tableProps: {
      fullWidth: true,
      testId: 'users-table',
      ref: tableRef,
      columns,
      data: users?.rows,
      onSort: setSort,
      defaultSort: sort,
      selectable: true,
      isPending: fetchingUsers,
      onRowSelect: ({ selectedFlatRows }) => {
        const selectedUsers = selectedFlatRows.map((user) => {
          return user.original.id;
        });
        setSelectedUserRows(selectedUsers);
      },
    },
    paginationProps: {
      variant: 'advanced',
      itemsPerPageOptions,
      itemsCount: users?.count,
      itemsPerPage: paginationState.limit,
      currentPage: paginationState.currentPage,
      isReset: paginationState.isReset,
      onPageChange: handlePaginationChange,
    },
  };

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        bulkActionsByUserType(currentUser?.userType?.id),
        UserFilters,
        TableWrapper,
      ),
    [],
  );

  return (
    <>
      <div className="users-table">
        {noExportUsers && (
          <div className="margin-bottom-3">
            <Alert
              data-testid="user-export-failure"
              type="error"
              heading=""
              noIcon={false}
              validation={false}
              focused={false}
              showClose={false}
            >
              No user records found to export. Please update filters to search
              user records.
            </Alert>
          </div>
        )}
        {userExportSuccess && (
          <div className="margin-bottom-3">
            <Alert
              data-testid="user-export-success"
              type="success"
              heading="Export Initiated:"
              noIcon={false}
              validation={false}
              focused={false}
              showClose
              onClose={() => {
                setUserExportSuccess(false);
              }}
            >
              The data you&apos;ve selected is now being processed for export{' '}
              and will be emailed to you upon completion. Depending on the file
              size, this may take up to 15 minutes or more, and will expire
              after 7 days. If you experience technical difficulties exporting,
              please contact the GSA Fleet Technical Support team at{' '}
              <a href="mailto:fleet.helpdesk@gsa.gov">fleet.helpdesk@gsa.gov</a>{' '}
              or <a href="tel:18664726711">866-472-6711</a> from 8:00 a.m. -
              7:00 p.m. ET, Monday-Friday.
            </Alert>
          </div>
        )}
        {fetchingExportUsers && <Spinner aria-busy="true" size="small" />}
        <CounterTag className="text-bold" count={selectedUserRows?.length}>
          Current selection:
        </CounterTag>
        <div className="margin-top-2">
          <FTF lowRightProps={userTableWrapperProps} filterToggle />
          <ConfirmRecertificationModal />
          {selectedUser && (
            <EditUserStatusModal
              userId={selectedUser.id}
              userFirstName={selectedUser.firstName}
              userLastName={selectedUser.lastName}
              userEmail={selectedUser.email}
              userStatusId={selectedUser.status.id}
              userStatusName={selectedUser.status.name}
              isOpen={isEditUserStatusOpen || false}
              onClose={() => {
                setIsEditUserStatusOpen(false);
              }}
              onDataChanged={() => {
                getData();
              }}
              onFeedback={(feedback) => {
                setFeedbackMessage(feedback);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UsersTable;
