import React from 'react';
import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  propTypeFormContext,
  StandardFieldset,
  StandardLabeledTextbox,
  StandardLabeledCheckbox,
  StandardLabelledReadonly,
} from '../../components/Forms';
import { Types } from '../admin-console/users/constants/user-details-constants';
import { canEditBidderPassport } from '../users/authorization';

export const UserProfileBasicInfo = ({ formContext, userTypeId, isInternational }) => {
  const ability = useAppAbility();

  return (
    <StandardFieldset label="Personal Information" name="personalInformation">
      <StandardLabelledReadonly
        formContext={formContext}
        name="email"
        label="Email"
      />
      <StandardLabeledTextbox
        formContext={formContext}
        name="firstName"
        label="First name"
        maxLength={50}
        required
      />
      <StandardLabeledTextbox
        formContext={formContext}
        name="lastName"
        label="Last name"
        maxLength={50}
        required
      />
      {canEditBidderPassport(ability) && isInternational && (
        <>
          <StandardLabeledTextbox
            formContext={formContext}
            name="passportNumber"
            label="Passport number"
            maxLength={9}
          />
          <StandardLabeledTextbox
            formContext={formContext}
            name="passportCountry"
            label="Country"
            maxLength={100}
          />
        </>
      )}
      {userTypeId === Types.CUSTOMER && (
        <StandardLabeledCheckbox
          formContext={formContext}
          name="isContractor"
          label="I am a non-federal employee working under contract with a federal agency"
        />
      )}
    </StandardFieldset>
  );
};

UserProfileBasicInfo.defaultProps = {
  isInternational: false,
};

UserProfileBasicInfo.propTypes = {
  formContext: propTypeFormContext.isRequired,
  userTypeId: PropTypes.string.isRequired,
  isInternational: PropTypes.bool,
};

export default UserProfileBasicInfo;
