import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Spinner,
  Alert,
  PageTitle,
  Fieldset,
  RadioButton,
  FormGroup,
} from '@gsa/afp-component-library';
import {
  useAppAbility,
  FleetTechnicalSupportContact,
  useTitle,
  Can,
} from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  createFormContext,
  StandardFieldset,
  StandardSeparator,
  StandardLabelledReadonly,
  StandardFormLabel,
  StandardControlledSelectDropdown,
} from '../../components/Forms';
import {
  Status,
  Types,
} from '../admin-console/users/constants/user-details-constants';
import { RequiredFieldsHintAlt } from '../../components/Required/RequiredFieldsHint';
import { USPSAddressValidation } from '../../components/USPSAddressValidation/usps-address-validation';
import {
  UPDATE_MY_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from '../../services/data-layer';
import { UserProfileAgencyBureauOffice } from './user-profile-agency-bureau-office';
import { UserProfileBasicInfo } from './user-profile-basic-info';
import { UserProfileVendorManager } from './user-profile-vendor-manager';
import validationOptions from './user-profile-validation';
import HqZonalChooser from './internal-user/hq-zonal-chooser';
import { capitalize, isGSAEmail } from '../../utilities/StringUtils';

import {
  USA_DOMESTIC_COUNTRY_CODE,
  UserProfileWorkAddress,
} from './user-profile-work-address';
import {
  USA_DOMESTIC_PHONE_CALLING_CODE,
  UserProfileWorkPhoneAlt,
} from './user-profile-work-phone-alt';
import './userprofile.scss';
import { useUserProfileData } from './useUserProfileData';
import { canManageAll, canEditBidderPassport } from '../users/authorization';
import { isFeatureEnabled } from '../../utilities/feature-toggle';

export const UserProfileForm = ({
  currentUser,
  setInvalidState,
  isAdminEditingUser = false,
}) => {
  if (!currentUser) {
    return null;
  }

  useTitle(capitalize(currentUser?.fullName));
  const ability = useAppAbility();
  const history = useHistory();

  const [profileSubmitSuccessful, setProfileSubmitSuccessful] = useState(false);
  const [addressToValidate, setAddressToValidate] = React.useState(null);
  const [userTypeId, _setUserTypeId] = useState(currentUser?.userType?.id);
  const [holdsWarrant, setHoldsWarrant] = useState(
    currentUser?.internalAttrs?.warrantLevel &&
      currentUser?.internalAttrs?.warrantLevel !== null,
  );

  const [
    isLoading,
    agenciesOptions,
    bureausOptions,
    officesOptions,
    countriesOptions,
    statesOptions,
    supervisorsOptions,
    vendorManagerOptions,
    phoneCountryCodesOptions,
    getBureaus,
    getOffices,
    getSupervisors,
    getVendorManagers,
    getBureausByPermission,
    getOfficesByPermission,
  ] = useUserProfileData(setInvalidState);

  const defaultValues = { ...currentUser };

  defaultValues.addrline1Domestic = currentUser.addrline1;
  defaultValues.addrline1International = currentUser.addrline1;

  defaultValues.cityDomestic = currentUser.city;
  defaultValues.cityInternational = currentUser.city;

  defaultValues.stateDomestic = currentUser.state;
  defaultValues.stateInternational = currentUser.state;
  defaultValues.countryCode =
    currentUser.countryCode ?? USA_DOMESTIC_COUNTRY_CODE;
  defaultValues.phoneNumberCountryCode =
    currentUser.phoneNumberCountryCode ?? USA_DOMESTIC_PHONE_CALLING_CODE;
  defaultValues.zipCodeDomestic = currentUser.postalCode || '';
  defaultValues.postalCodeInternational = currentUser.postalCode || '';
  defaultValues.supervisorId = currentUser?.supervisorId || '';
  defaultValues.isContractor = currentUser.customerAttrs?.isContractor || false;
  defaultValues.registeredAgencyCode =
    currentUser.customerAttrs?.registeredAgencyCode || '';
  defaultValues.registeredBureauCode =
    currentUser.customerAttrs?.registeredBureauCode || '';
  defaultValues.registeredOfficeCode =
    currentUser.customerAttrs?.registeredOfficeCode || '';
  defaultValues.divisionId =
    currentUser.internalAttrs?.division?.id.toString() || '';
  defaultValues.branchId =
    currentUser.internalAttrs?.branch?.id.toString() || '';
  defaultValues.zoneId = currentUser.internalAttrs?.zone?.id.toString() || '';
  defaultValues.managementCenterId =
    currentUser.internalAttrs?.managementCenter?.id.toString() || '';
  defaultValues.fieldOfficeId =
    currentUser.internalAttrs?.fieldOffice?.id.toString() || '';
  defaultValues.fleetVendorNumber =
    currentUser.vendorAttrs?.fleetVendorNumber.toString() || '';
  defaultValues.warrantLevel =
    currentUser.internalAttrs?.warrantLevel?.toString() || '10000';
  defaultValues.passportCountry =
    currentUser.bidderProfile?.passportCountry?.toString() || '';
  defaultValues.passportNumber =
    currentUser.bidderProfile?.decryptedPassportNumber?.toString() || '';

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
  });

  const setUserTypeId = (userType) => {
    setValue('userType.id', userType);
    _setUserTypeId(userType);
  };

  const getAgencyCode = () => {
    return isAdminEditingUser && !canManageAll(ability)
      ? defaultValues?.customerAttrs?.registeredAgencyCode
      : getValues('registeredAgencyCode');
  };

  const formContext = createFormContext(
    control,
    register,
    watch,
    errors,
    defaultValues,
    validationOptions,
  );

  // Load agency bureau on load if the profile has data already
  useEffect(() => {
    if (defaultValues.registeredAgencyCode) {
      if (isAdminEditingUser && !canManageAll(ability)) {
        getBureausByPermission(
          'update',
          'User',
          defaultValues.registeredAgencyCode,
        );
      } else {
        getBureaus(defaultValues.registeredAgencyCode);
      }
    }

    if (
      defaultValues.registeredAgencyCode &&
      defaultValues.registeredBureauCode
    ) {
      if (isAdminEditingUser && !canManageAll(ability)) {
        getOfficesByPermission(
          'update',
          'User',
          defaultValues.registeredAgencyCode,
          defaultValues.registeredBureauCode,
        );
      } else {
        // eslint-disable-next-line no-unused-expressions
        getOffices(
          defaultValues.registeredAgencyCode,
          defaultValues.registeredBureauCode,
        );
      }
    }
    getSupervisors(
      userTypeId,
      defaultValues.registeredAgencyCode || '',
      defaultValues.registeredBureauCode || '',
      defaultValues.registeredOfficeCode || '',
    );
  }, []);

  useEffect(() => {
    const { fleetVendorNumber } = defaultValues;
    if (fleetVendorNumber) {
      getVendorManagers(fleetVendorNumber);
    }
  }, [defaultValues.fleetVendorNumber]);

  const [updateMyUserProfile, { loading: isUpdatingUserProfile }] = useMutation(
    UPDATE_MY_USER_PROFILE,
    {
      onError: () => setInvalidState(true, 'update user profile'),
      onCompleted: () => setProfileSubmitSuccessful(true),
    },
  );

  const [updateUserProfile, { loading: isUpdatingUserDetails }] = useMutation(
    UPDATE_USER_PROFILE,
    {
      onError: () => setInvalidState({ type: 'error' }),
      onCompleted: () => {
        history.push({
          pathname: `/admin/users/${currentUser.id}`,
          state: { userDetailsUpdated: true },
        });
      },
    },
  );

  const onCancel = () => {
    history.push(`/admin/users/${currentUser.id}`);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return '';
    }
    const phoneValidationRegEx = /\D/gi;
    return phoneNumber.replace(phoneValidationRegEx, '');
  };

  const commitSubmit = () => {
    const formDataToCommit = control.getValues();

    const {
      firstName,
      lastName,
      passportCountry,
      passportNumber,
      isContractor,
      countryCode,
      addrline1Domestic,
      addrline1International,
      addrline2,
      cityDomestic,
      cityInternational,
      stateDomestic,
      stateInternational,
      postalCodeInternational,
      zipCodeDomestic,
      registeredBureauCode,
      registeredOfficeCode,
      phoneNumber,
      phoneNumberExt,
      phoneNumberCountryCode,
      supervisorId,
      branchId,
      divisionId,
      zoneId,
      managementCenterId,
      fieldOfficeId,
    } = formDataToCommit;
    const { email } = currentUser;

    const userProfileInput = {
      id: currentUser.id,
      firstName,
      lastName,
      email,
      supervisorId:
        supervisorId === '-' || supervisorId === '' ? null : supervisorId,
      customerAttrsInput: {
        registeredAgencyCode: getAgencyCode(),
        registeredBureauCode:
          registeredBureauCode === '-' ? null : registeredBureauCode,
        registeredOfficeCode:
          registeredOfficeCode === '-' ? null : registeredOfficeCode,
        isContractor: isContractor === true,
      },
      internalAttrsInput: {
        branchId: Number(branchId),
        divisionId: Number(divisionId),
        zoneId: Number(zoneId),
        managementCenterId: Number(managementCenterId),
        fieldOfficeId:
          Number(fieldOfficeId) === 0 ? null : Number(fieldOfficeId),
        warrantLevel: holdsWarrant
          ? Number(formDataToCommit.warrantLevel)
          : null,
      },
      addrline1:
        countryCode === USA_DOMESTIC_COUNTRY_CODE
          ? addrline1Domestic
          : addrline1International,
      addrline2,
      city:
        countryCode === USA_DOMESTIC_COUNTRY_CODE
          ? cityDomestic
          : cityInternational,
      state:
        countryCode === USA_DOMESTIC_COUNTRY_CODE
          ? stateDomestic
          : stateInternational,
      postalCode:
        countryCode === USA_DOMESTIC_COUNTRY_CODE
          ? zipCodeDomestic
          : postalCodeInternational,
      countryCode,
      phoneNumber: formatPhoneNumber(phoneNumber),
      phoneNumberExt,
      phoneNumberCountryCode,
    };
    userProfileInput.userTypeId = parseInt(userTypeId, 10);
    if (userTypeId === Types.GSA_EMPLOYEE) {
      userProfileInput.customerAttrsInput = null;
    } else if (userTypeId === Types.CUSTOMER) {
      userProfileInput.internalAttrsInput = null;
    }

    if (canEditBidderPassport(ability)) {
      userProfileInput.bidderProfileInput = {
        passportCountry,
        passportNumberInput: passportNumber,
      };
    }

    if (isAdminEditingUser) {
      updateUserProfile({
        variables: { userProfileInput },
      });
      return;
    }

    updateMyUserProfile({
      variables: { userProfileInput },
    });
  };

  const onSubmit = () => {
    const {
      countryCode,
      addrline1Domestic: address,
      addrline2: address2,
      cityDomestic: city,
      stateDomestic,
      zipCodeDomestic: zip,
    } = control.getValues();

    if (
      countryCode === USA_DOMESTIC_COUNTRY_CODE &&
      address &&
      city &&
      stateDomestic &&
      zip
    ) {
      setAddressToValidate({
        countryCode,
        address,
        address2,
        city,
        state: stateDomestic,
        zip,
      });
    } else {
      commitSubmit();
    }
  };

  const updateOffices = (agencyCode, bureauCode) => {
    setValue('registeredOfficeCode', '-', { shouldValidate: true });

    if (isAdminEditingUser && !canManageAll(ability)) {
      getOfficesByPermission('update', 'User', agencyCode, bureauCode);
    } else {
      getOffices(agencyCode, bureauCode);
    }
  };

  const updateBureaus = (agencyCode) => {
    setValue('registeredBureauCode', '-', { shouldValidate: true });
    setValue('registeredOfficeCode', '-', { shouldValidate: true });

    if (isAdminEditingUser && !canManageAll(ability)) {
      getBureausByPermission('update', 'User', agencyCode);
    } else {
      getBureaus(agencyCode);
    }

    updateOffices(agencyCode, '-');
  };

  const updateSupervisors = (agencyCode, bureauCode, officeCode) => {
    setValue('supervisorId', null, { shouldValidate: false });
    getSupervisors(
      userTypeId,
      agencyCode,
      bureauCode,
      officeCode,
    );
  };

  const onAgencyChange = (evt) => {
    const agencyCode = evt.target.value;
    updateBureaus(agencyCode);
    updateSupervisors(agencyCode, '', '');
  };

  const onBureauChange = (evt) => {
    const agencyCode = getAgencyCode();
    const bureauCode = evt.target.value;

    updateOffices(agencyCode, bureauCode);
    updateSupervisors(agencyCode, bureauCode, '');
  };

  const onOfficeChange = (evt) => {
    const agencyCode = getAgencyCode();
    const bureauCode = getValues('registeredBureauCode');
    const officeCode = evt.target.value;
    updateSupervisors(agencyCode, bureauCode, officeCode);
  };

  useEffect(() => {
    const agencyCode = defaultValues.registeredAgencyCode || '';
    const bureauCode = defaultValues.registeredBureauCode || '';
    const officeCode = defaultValues.registeredOfficeCode || '';
    updateSupervisors(agencyCode, bureauCode, officeCode);
  }, [userTypeId]);
  
  const onSaveAddress = ({ address, address2, city, state, zip }) => {
    control.setValue('addrline1Domestic', address);
    control.setValue('addrline2', address2);
    control.setValue('cityDomestic', city);
    control.setValue('stateDomestic', state);
    control.setValue('zipCodeDomestic', zip);
    setAddressToValidate(null);
    commitSubmit();
  };

  if (profileSubmitSuccessful) {
    return (
      <div className="margin-bottom-6">
        <PageTitle
          className="successful-header"
          title="Profile Submitted for Approval"
        />
        <p className="font-sans-xs successful-text">
          Thank you for submitting your profile. You will receive an email with
          updates from an authorized approver.
        </p>
      </div>
    );
  }

  const busy =
    isLoading ||
    isUpdatingUserProfile ||
    isUpdatingUserDetails;

  const getVendorWorkInformation = () => {
    return (
      <StandardFieldset label="Work Information" name="workInformation">
        <StandardLabelledReadonly
          formContext={formContext}
          defaultValue={currentUser?.vendorAttrs?.vendorName || ''}
          name="vendorName"
          label="Vendor name"
        />
      </StandardFieldset>
    );
  };

  const getCustomerWorkInformation = () => {
    return (
      <>
        <UserProfileAgencyBureauOffice
          formContext={formContext}
          agenciesOptions={agenciesOptions}
          bureausOptions={bureausOptions}
          officesOptions={officesOptions}
          supervisorsOptions={supervisorsOptions}
          isAdminEditingUser={isAdminEditingUser}
          onAgencyChange={onAgencyChange}
          onBureauChange={onBureauChange}
          onOfficeChange={onOfficeChange}
        />

        <StandardFieldset label="Work Address" name="workAddress">
          <p>Please provide your current work address.</p>
          <UserProfileWorkAddress
            formContext={formContext}
            countriesOptions={countriesOptions}
            statesOptions={statesOptions}
          />

          {addressToValidate && (
            <USPSAddressValidation
              addressToValidate={addressToValidate}
              saveButtonText="Save and close"
              onSave={onSaveAddress}
              onCancel={() => setAddressToValidate(null)}
            />
          )}
        </StandardFieldset>
      </>
    );
  };

  const getGSAWorkInformation = () => {
    return (
      <HqZonalChooser
        setInvalidState={setInvalidState}
        formContext={formContext}
        phoneCountryCodesOptions={phoneCountryCodesOptions}
      />
    );
  };

  const getPhoneInformation = () => {
    const label =
      userTypeId === Types.CUSTOMER ? 'Work Phone Number' : 'Phone Number';
    return (
      <StandardFieldset label={label} name="phoneNumber">
        <UserProfileWorkPhoneAlt
          formContext={formContext}
          phoneCountryCodes={phoneCountryCodesOptions}
        />
      </StandardFieldset>
    );
  };

  const getUserIdentification = () => {
    return (
      <StandardFieldset label="User Identification" name="userTypeId">
        <p className="margin-bottom-0">Please select you user type.</p>
        <Fieldset legend="How do you identify yourself?" required>
          <RadioButton
            name="GSAEmployee"
            label="GSA Fleet internal employee"
            onChange={() => setUserTypeId(Types.GSA_EMPLOYEE)}
            checked={userTypeId === Types.GSA_EMPLOYEE}
            value={Types.GSA_EMPLOYEE}
            data-testid="user-identification-internal"
          />
          <RadioButton
            name="customer"
            label="GSA Fleet customer"
            onChange={() => setUserTypeId(Types.CUSTOMER)}
            checked={userTypeId === Types.CUSTOMER}
            value={Types.CUSTOMER}
            data-testid="user-identification-customer"
          />
        </Fieldset>
      </StandardFieldset>
    );
  };

  function canChangeUserIdentification() {
    const email = currentUser?.email ?? '';
    const userStatusId = currentUser?.status?.id;
    return (
      isGSAEmail(email) &&
      ((Status.PENDING_APPROVAL === userStatusId && canManageAll(ability)) ||
        (Status.PENDING_PROFILE === userStatusId && !isAdminEditingUser))
    );
  }

  function getWorkInformation() {
    return (
      <>
        {canChangeUserIdentification() && getUserIdentification()}

        {userTypeId === Types.VENDOR && getVendorWorkInformation()}

        {userTypeId === Types.CUSTOMER && getCustomerWorkInformation()}

        {userTypeId === Types.GSA_EMPLOYEE && getGSAWorkInformation()}

        {userTypeId !== Types.GSA_EMPLOYEE && getPhoneInformation()}
      </>
    );
  }

  return (
    <div>
      {busy && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}

      {!isAdminEditingUser && (
        <>
          <Alert
            className="alert"
            type="warning"
            heading="We need your profile information"
          >
            Please complete your user profile to log in to{' '}
            <a href="https://gsafleet.gov">GSAFleet.gov</a>. Once your completed
            profile is submitted, an authorized approver will review the
            information. For technical support, <FleetTechnicalSupportContact />
            .
          </Alert>

          <div>
            <PageTitle className="capitalize" title="Profile" />
          </div>
          <h3>
            Please provide the following information so we can get your account
            setup for approval.
          </h3>
        </>
      )}

      <RequiredFieldsHintAlt />

      <Form
        noValidate
        className="usa-form usa-form--large margin-bottom-6"
        data-testid="userprofile-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <UserProfileBasicInfo
          formContext={formContext}
          userTypeId={userTypeId}
          isInternational={currentUser.bidderProfile && (currentUser?.bidderProfile?.type === 'INTERNATIONAL_INDIVIDUAL' || currentUser?.bidderProfile?.type === 'INTERNATIONAL_BUSINESS')}
        />

        {getWorkInformation()}

        {ability && isFeatureEnabled('accident-and-maintenance') && (
          <Can I="view" a="warrantLevel">
            <StandardFieldset label="Warrant Level" name="warrantLevel">
              <FormGroup>
                <StandardFormLabel required>
                  Do you hold a warrant?
                </StandardFormLabel>
                <RadioButton
                  label="No"
                  value="No"
                  name="holdsWarrant"
                  checked={!holdsWarrant}
                  onChange={() => {
                    setHoldsWarrant(false);
                  }}
                  required
                />
                <RadioButton
                  label="Yes"
                  value="Yes"
                  name="holdsWarrant"
                  checked={holdsWarrant}
                  onChange={() => {
                    setHoldsWarrant(true);
                  }}
                  required
                />
              </FormGroup>

              {holdsWarrant && (
                <FormGroup>
                  <StandardFormLabel required>Warrant limit</StandardFormLabel>
                  <StandardControlledSelectDropdown
                    formContext={formContext}
                    name="warrantLevel"
                    options={[
                      { label: '$10,000', value: 10000 },
                      { label: '$25,000', value: 25000 },
                      { label: '$250,000', value: 250000 },
                    ]}
                    required
                  />
                </FormGroup>
              )}
            </StandardFieldset>
          </Can>
        )}

        {userTypeId === Types.VENDOR && (
          <UserProfileVendorManager
            formContext={formContext}
            vendorManagerOptions={vendorManagerOptions}
          />
        )}

        <StandardFieldset name="submission">
          {!isAdminEditingUser && (
            <>
              <StandardSeparator />
              <p style={{ textAlign: 'left' }}>
                Upon submission, your profile will be sent to authorized
                approver to confirm your information. You will receive an email
                with updates.
              </p>
            </>
          )}

          <Button
            label="Cancel"
            variant="unstyled"
            id="cancel-userprofile"
            className="usa-button margin-top-1 margin-right-1"
            data-testid="cancel-form"
            onClick={onCancel}
          />
          <Button
            label={
              !isAdminEditingUser
                ? 'Submit profile for approval'
                : 'Update profile'
            }
            variant="primary"
            id="create-userprofile"
            className="usa-button margin-top-1"
            data-testid="submit-form"
            type="submit"
          />
        </StandardFieldset>
      </Form>
    </div>
  );
};

UserProfileForm.defaultValues = {
  isContractor: false,
  state: '',
  countryCode: '',
  phoneNumberCountryCode: '',
  postalCode: '',
  customerAttrs: null,
  internalAttrs: null,
  vendorAttrs: null,
};
UserProfileForm.defaultProps = {
  isAdminEditingUser: false,
};
UserProfileForm.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    addrline1: PropTypes.string,
    addrline2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    countryCode: PropTypes.string,
    phoneNumberCountryCode: PropTypes.string,
    postalCode: PropTypes.string,
    supervisorId: PropTypes.string,
    supervisorUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
    }),
    vendorAttrs: PropTypes.shape({
      fleetVendorNumber: PropTypes.string,
      vendorName: PropTypes.string
    }),
    customerAttrs: PropTypes.shape({
      registeredAgencyCode: PropTypes.string,
      registeredBureauCode: PropTypes.string,
      registeredOfficeCode: PropTypes.string,
      isContractor: PropTypes.bool,
    }),
    internalAttrs: PropTypes.shape({
      zone: PropTypes.shape({
        id: PropTypes.number,
      }),
      managementCenter: PropTypes.shape({
        id: PropTypes.number,
      }),
      division: PropTypes.shape({
        id: PropTypes.string,
      }),
      branch: PropTypes.shape({
        id: PropTypes.number,
      }),
      fieldOffice: PropTypes.shape({
        id: PropTypes.number,
      }),
      warrantLevel: PropTypes.number,
    }),
    bidderProfile: PropTypes.shape({
      passportCountry: PropTypes.string,
      decryptedPassportNumber: PropTypes.string,
      type: PropTypes.string,
    }),
    userType: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }).isRequired,
    status: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  setInvalidState: PropTypes.func.isRequired,
  isAdminEditingUser: PropTypes.bool,
};

export default UserProfileForm;
