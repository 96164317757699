import Regex from '../../utilities/RegexUtils';

export const validateRequiredDropdown = (message) => {
  return {
    [message]: (c) => !!c && c !== '-' && c !== '',
  };
};

const validationOptions = {
  firstName: {
    required: 'Please provide your first name',
    maxLength: {
      value: 50,
      message: 'First name should be 50 characters or fewer',
    },
    pattern: {
      value: Regex.name,
      message: 'First name is not valid',
    },
  },
  lastName: {
    required: 'Please provide your last name',
    maxLength: {
      value: 50,
      message: 'Last name should be 50 characters or fewer',
    },
    pattern: {
      value: Regex.name,
      message: 'Last name is not valid',
    },
  },
  passportNumber: {
    required: 'Please provide your passport number',
    maxLength: {
      value: 9,
      message: 'Passport number should be 9 characters or fewer',
    },
  },
  passportCountry: {
    required: 'Please provide your passport country',
    maxLength: {
      value: 100,
      message: 'Passport country should be 100 characters or fewer',
    },
  },
  isContractor: {},
  phoneNumberCountryCode: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select a country code'),
  },
  phoneNumberDomestic: {
    required: 'Please provide a phone number',
    pattern: {
      value: Regex.phoneNumberDomestic,
      message: 'Phone number is not valid',
    },
    shouldUnregister: true,
  },
  phoneNumberInternational: {
    required: 'Please provide a phone number',
    pattern: {
      value: Regex.phoneNumberInternational,
      message: 'Phone number is not valid',
    },
    shouldUnregister: true,
  },
  phoneNumberExtDomestic: {
    pattern: {
      value: Regex.phoneNumberExtDomestic,
      message: 'Extension is not valid',
    },
    shouldUnregister: true,
  },
  phoneNumberExtInternational: {
    pattern: {
      value: Regex.phoneNumberExtInternational,
      message: 'Extension is not valid',
    },
    shouldUnregister: true,
  },

  registeredAgencyCode: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select an agency'),
  },
  divisionId: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select a division'),
  },
  branchId: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select a branch'),
  },
  zoneId: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select a zone'),
  },
  managementCenterId: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select a Fleet Management Center'),
  },

  countryCode: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select a country'),
  },
  addrline1Domestic: {
    required: 'Please provide an address',
    shouldUnregister: true,
  },
  addrline1International: {
    shouldUnregister: true,
  },
  addrline2: {},
  cityDomestic: {
    required: 'Please provide a city',
    shouldUnregister: true,
  },
  cityInternational: {
    shouldUnregister: true,
  },
  stateDomestic: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select a state'),
  },
  stateInternational: {},
  zipCodeDomestic: {
    required: 'Please provide a zip code',
    pattern: {
      value: Regex.zipCodeDomestic,
      message: 'Zip code is not valid',
    },
    shouldUnregister: true,
  },
  postalCodeInternational: {
    // Not currently required
    shouldUnregister: true,
  },
  supervisorId: {
    required: 'Required',
    validate: validateRequiredDropdown('Please select your manager'),
  },
};

export default validationOptions;
